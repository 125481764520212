* {
  box-sizing: border-box;
}
:root {
  --black: #191a1a;
  --white: #ffffff;
  --primary: #fbaf00;
  --grey: #eaebeb;
  --light-grey: #f4f5f5;
  --semi-grey: #727878;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: AtypDisplay;
  font-weight: 400;
  color: var(--black);
  max-width: 1440px;
  margin: 0 auto;
  
}

@font-face {
  font-family: AtypDisplay;
  src: url("../assets/fonts/AtypDisplay-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: AtypDisplay;
  src: url("../assets/fonts/AtypText-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: AtypDisplay;
  src: url("../assets/fonts/AtypText-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: AtypDisplay;
  src: url("../assets/fonts/AtypText-Semibold.ttf");
  font-weight: 500;
}

/* h1,
h2,
h3,
h4 {
  font-family: AtypDisplay;
  font-weight: 700;
} */

h1 {
  font-size: 64px;
  line-height: 72px;
}
h2 {
  font-size: 34px;
  line-height: 48px;
}
h3 {
  line-height: 32px;
  font-size: 24px;
}
h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}
h5 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -1px;
}
h6 {
  font-weight: 700;
  font-size: 24px;
  line-height: 21px;
}
p {
  font-size: 16px;
  line-height: 25px;
}
ul {
  list-style: none;
}
input {
  outline: none;
}
label{
  font-size: 16px;
}
.subtitle {
  font-size: 12px;
  line-height: 16px;
}
.subtitle1 {
  font-size: 14px;
  line-height: 20px;
}
.subtitle2 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.primary_btn {
  background-color: var(--black);
  color: var(--white);
}
.pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pagination-data {
  display: flex;
}
.pagination-data > li:first-child {
  width: 55%;
  margin-left:-20px;
}

.rc-pagination-item-active {
  background: #A0A6A6 !important;
  color: white !important;
}
.rc-pagination-disabled svg {
  color: grey !important;
}
.pagination-data li:first-child {
  margin-right: 300px;
  float: right;
}
.rc-pagination-total-text {
  border: none !important;
}
.pagination-data li {
  border: 1px solid #A3A3A3;
  border-radius: 5px;
  margin-right: 10px;;
  padding: 10px 15px;
  cursor: pointer;
}
.pagination-data li:last-child {
  border: none !important;
  margin-left: -20px;
}
.pagination-data li:nth-child(2){
  border: none !important;
  margin-right: -10px;
}
.ant-popover-arrow,.ant-picker-header-super-next-btn, .ant-picker-header-super-prev-btn, .ant-picker-footer, .ant-picker-suffix {
  display: none !important;
}
.ant-picker-cell-inner::before {
  background: #EAEBEB !important;
border-radius: 5px !important;
border : none !important;
z-index: -2 !important;
color: #fff !important;
}
:where(.css-dev-only-do-not-override-26rdvq).ant-picker {
  border: none !important;
}
:where(.css-dev-only-do-not-override-26rdvq).ant-picker input {
  /* border: none !important; */
  cursor: pointer !important;
  margin-top: 4px !important;
  /* width: 283px !important; */
  /* border: 3px solid red !important; */
}
/* :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-selected) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-range-start)  {
  color: #fff !important;
  z-index: -2 !important;

} */

:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-selected) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-range-start) .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-26rdvq).ant-picker-dropdown .ant-picker-cell-range-end) .ant-picker-cell-inner {
  color: #fff !important;
  background: #191A1A !important;
border-radius: 5px !important;
border : none !important;
/* z-index: -2 !important; */
}
.ant-picker-cell-inner {
  font-family: 'Atyp Display' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 14px !important;
color: #A0A6A6 !important;
/* line-height: 40px !important; */
/* border: 3px solid red; */
/* padding:0px 40px 0px 40px !important; */
}
.ant-picker-cell-inner::before #text {
  background: #191A1A !important;
border-radius: 5px !important;
border : none !important;
color: #fff !important;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  position: fixed;
  padding: 4px;
  width: fit-content !important;
  box-sizing: border-box;
  color: #fff;
}
/* .ant-popover-content {
  display: none;
} */

@media (max-width: 1280px) {
}

@media (max-width: 576px) {
}
